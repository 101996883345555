import { createRouter, createWebHistory } from 'vue-router'
import EventsView from '../views/EventsView.vue'
import AboutView from '../views/AboutView.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import DataAddforMS from '../views/DataAddforMS.vue'
import Favorite from '../views/FavoriteEventsPage.vue'
import EventDetails from '../components/EventDetails.vue'; 
import ReportsView from '@/views/ReportsView.vue';
import MyEventsView from '@/views/MyEvents.vue';

const routes = [
  {
    path: '/',
    redirect: '/login' // Добавляем редирект на страницу /Login
  },
  
  {
    path: '/login',
    name: 'loginpage',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/EventsView',
    name: 'EventsView',
    component: EventsView
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/DataAddforMS',
    name: 'DataAddforMS',
    component: DataAddforMS
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: EventDetails,
    props: true
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: Favorite,
  },
  {
    path: '/report',
    name: 'Report',
    component: ReportsView
  },
  {
    path: '/myevents',
    name: 'myevents',
    component: MyEventsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router