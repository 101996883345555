import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; 
import Emitter from 'tiny-emitter';

const app = createApp(App);
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
console.log('VUE_APP_SERVER:', process.env.VUE_APP_SERVER);
app.use(store).use(router).mount('#app');