<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <div class="header">
            Добавление мероприятия
          </div>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div class="home">
              <form @submit.prevent="goaddEvent()" class="event-form">
                <label for="eventName">Название</label>
                <input type="text" id="eventName" name="name" class="form-input" placeholder="Название" required
                  v-model="eventName">

                <label for="eventDate">Дата</label>
                <input type="date" id="eventDate" name="date" class="form-input" placeholder="Дата" required
                  v-model="eventDate">

                <label for="eventTimeStart">Время начала</label>
                <input type="time" id="eventTimeStart" name="time" class="form-input" placeholder="Время начала" required
                  v-model="eventTimeStart">
                  <label for="eventTimeEnd">Время окончания</label>
                <input type="time" id="eventTimeEnd" name="time" class="form-input" placeholder="Время окончания" required
                  v-model="eventTimeEnd">

                <label for="eventDescription">Описание</label>
                <input type="text" id="eventDescription" name="description" class="form-input" placeholder="Описание"
                  required v-model="eventDescription">

                <label for="eventImageName">Название изображения</label>
                <input type="text" id="eventImageName" name="imageName" class="form-input"
                  placeholder="Название изображения" required v-model="eventImageName">

                <label for="eventOrganizator">Организатор</label>
                <select id="eventOrganizator" name="organizator" class="form-input" required v-model="eventOrganizator">
                  <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                </select>

                <label for="eventSuborganizator">Соорганизатор (-ы)</label>
                <div v-for="(suborg, index) in eventSuborganizator" :key="index" class="suborg-input">
                  <select class="form-input" v-model="eventSuborganizator[index]">
                    <option v-for="org in organizators" :key="org.uid" :value="org.uid">{{ org.fullname }}</option>
                  </select>
                  <button type="button" @click="removeSuborganizator(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addSuborganizator" class="btn-add">Добавить соорганизатора</button>

                <label for="eventLocation">Место проведения</label>
                <input type="text" id="eventLocation" name="location" class="form-input" placeholder="Место проведения"
                  required v-model="eventLocation">

                <label for="eventClass">Класс мероприятия</label>
                <select id="eventClass" name="EventClass" class="form-input" required v-model="eventClass">
                  <option v-for="cls in EventClasses" :key="cls.uid" :value="cls.uid">{{ cls.name }}</option>
                </select>

                <label for="eventType">Тип мероприятия</label>
                <select id="eventType" name="EventType" class="form-input" required v-model="eventType">
                  <option v-for="type in EventTypes" :key="type.uid" :value="type.uid">{{ type.name }}</option>
                </select>

                <label for="eventLevel">Уровень</label>
                <select id="eventLevel" name="Level" class="form-input" required v-model="eventLevel">
                  <option v-for="lvl in Levels" :key="lvl.uid" :value="lvl.uid">{{ lvl.name }}</option>
                </select>

                <label for="eventSubType">Подтип мероприятия</label>
                <select id="eventSubType" name="EventSubType" class="form-input" required v-model="eventSubType">
                  <option v-for="subType in EventSubTypes" :key="subType.uid" :value="subType.uid">{{ subType.name }}
                  </option>
                </select>

                <label for="eventMember">Участники</label>
                <div v-for="(member, index) in eventMember" :key="index" class="member-input">
                  <select class="form-input" v-model="eventMember[index]">
                    <option v-for="mem in Members" :key="mem.uid" :value="mem.uid">{{ mem.name }}</option>
                  </select>
                  <button type="button" @click="removeMember(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addMember" class="btn-add">Добавить участника</button>

                <label for="eventOrganizationForm">Форма организации</label>
                <select id="eventOrganizationForm" name="OrganizationForm" class="form-input" required
                  v-model="eventOrganizationForm">
                  <option v-for="form in OrganizationForms" :key="form.uid" :value="form.uid">{{ form.name }}</option>
                </select>

                <label for="eventResponsible">Ответственный</label>
                <input type="text" id="eventResponsible" name="Responsible" class="form-input"
                  placeholder="Ответственный" required v-model="eventResponsible">
                  <label for="eventOrganizerEmails">Email организаторов</label>
                <div v-for="(email, index) in responsiblelogin" :key="index" class="email-input">
                  <input type="email" class="form-input" placeholder="Email организатора" v-model="responsiblelogin[index]" required>
                  <button type="button" @click="removeOrganizerEmail(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addOrganizerEmail" class="btn-add">Добавить email организатора</button>
                <p class="note">Все email, которые вы добавите, будут иметь доступ к управлению мероприятием!</p>
                  <label for="eventAdditionalLinks">Дополнительные ссылки</label>
                <div v-for="(link, index) in eventAdditionalLinks" :key="index" class="link-input">
                  <input type="text" class="form-input" placeholder="Ссылка" v-model="eventAdditionalLinks[index]">
                  <button type="button" @click="removeLink(index)" class="btn-remove">Удалить</button>
                </div>
                <button type="button" @click="addLink" class="btn-add">Добавить ссылку</button>
                <button type="submit" class="form-btn">Добавить мероприятие</button>
              </form>
              <h3>Или загрузите файл, содержащий таблицу, которая заполнена по шаблону</h3>
              <input type="file" @change="handleFileUpload" class="file-input">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer">
            <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Идет загрузка данных...</p>
    </div>
  </transition>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import * as XLSX from 'xlsx';
import moment from 'moment';

export default {
  name: 'ModalAddEvent',
  data() {
    return {
      eventName: '',
      eventDate: '',
      eventTimeStart: '',
      eventTimeEnd: '',
      eventDescription: '',
      eventImageName: '',
      eventOrganizator: '',
      eventSuborganizator: [],
      eventLocation: '',
      eventClass: '',
      eventType: '',
      eventLevel: '',
      eventSubType: '',
      eventMember: [],
      eventAdditionalLinks: [],
      eventOrganizationForm: '',
      eventResponsible: '',
      responsiblelogin: [], // Добавляем массив для хранения email организаторов
      loading: false
    }
  },
  computed: {
    ...mapState('references', [
      'organizators',
      'addTypes',
      'EventClasses',
      'EventTypes',
      'Levels',
      'EventSubTypes',
      'Members',
      'OrganizationForms'
    ])
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    ...mapActions('references', [
      'getorganizators',
      'getaddtypes',
      'geteventclasses',
      'geteventtypes',
      'getlevels',
      'geteventsubtypes',
      'getmembers',
      'getorganizationforms'
    ]),
    ...mapActions('event', [
      'addevent',
      'getallevents'
    ]),
    async goaddEvent() {
      if (!this.validateForm()) {
        window.alert('Пожалуйста, заполните все обязательные поля');
        return;
      }

      const formattedDate = moment(this.eventDate).format('YYYY-MM-DD');
      const formData = {
        name: this.eventName,
        date: formattedDate,
        timestart: this.eventTimeStart || 'не определено', // Добавляем проверку на null
        timeend: this.eventTimeEnd || 'не определено', // Добавляем проверку на null
        description: this.eventDescription,
        imagename: this.eventImageName,
        organizator: this.eventOrganizator,
        location: this.eventLocation,
        eventclass: this.eventClass,
        eventtype: this.eventType,
        level: this.eventLevel,
        eventsubtype: this.eventSubType,
        members: this.eventMember.filter(member => member), // Фильтруем пустые значения
        organizationform: this.eventOrganizationForm,
        responsible: this.eventResponsible,
        addtype: this.getAddType('Вручную'),
        responsiblelogin: this.responsiblelogin.filter(email => email) // Добавляем email организаторов
      };

      // Фильтруем пустые значения из массивов
      if (this.eventSuborganizator.length > 0) {
        formData.suborganizator = this.eventSuborganizator.filter(suborg => suborg);
      }

      if (this.eventAdditionalLinks.length > 0) {
        formData.additionallinks = this.eventAdditionalLinks.filter(link => link);
      }

      console.log('Form Data:', formData); // Добавлено для отладки

      try {
        await this.addevent(formData);
        console.log(formData);
        window.alert('Мероприятие добавлено');
        this.getallevents();
      } catch (error) {
        console.error('Ошибка при добавлении мероприятия:', error);
      }
    },
    validateForm() {
      return this.eventName && this.eventDate && this.eventTimeStart && this.eventTimeEnd && this.eventDescription && this.eventImageName &&
        this.eventOrganizator && this.eventLocation && this.eventClass && this.eventType &&
        this.eventLevel && this.eventSubType && this.eventMember.length > 0 && this.eventOrganizationForm &&
        this.eventResponsible && this.responsiblelogin.length > 0; // Добавляем проверку на наличие хотя бы одного email организатора
    },
    addLink() {
      this.eventAdditionalLinks.push('');
    },
    removeLink(index) {
      this.eventAdditionalLinks.splice(index, 1);
    },
    addSuborganizator() {
      this.eventSuborganizator.push('');
    },
    removeSuborganizator(index) {
      this.eventSuborganizator.splice(index, 1);
    },
    addMember() {
      this.eventMember.push('');
    },
    removeMember(index) {
      this.eventMember.splice(index, 1);
    },
    addOrganizerEmail() {
      this.responsiblelogin.push('');
    },
    removeOrganizerEmail(index) {
      this.responsiblelogin.splice(index, 1);
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        for (const row of jsonData) {
          // Проверяем, является ли дата строкой
          if (typeof row.date !== 'string') {
            console.error('Неверный формат даты:', row.date);
            continue; // Пропускаем эту строку
          }

          // Парсим дату из формата "DD.MM.YY" в формат "YYYY-MM-DD"
          const dateParts = row.date.split('.');
          if (dateParts.length !== 3) {
            console.error('Неверный формат даты:', row.date);
            continue; // Пропускаем эту строку
          }

          // Преобразуем в формат "YYYY-MM-DD"
          const formattedDate = `20${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

          const eventData = {
            name: row.name || 'не определено',
            date: formattedDate, // Используем отформатированную дату
            timestart: row.timestart || 'не определено', // Добавляем проверку на null
            timeend: row.timeend || 'не определено', // Добавляем проверку на null
            description: row.description || 'не определено',
            imagename: row.imagename || 'не определено',
            organizator: this.findOrganizator(row.organizator) || 'не определено',
            suborganizator: this.parseSuborganizators(row.suborganizator) || [],
            location: row.location || 'не определено',
            eventclass: this.findEventClass(row.eventclass) || 'не определено',
            eventtype: this.findEventType(row.eventtype) || 'не определено',
            level: this.findEventLevel(row.level) || 'не определено',
            eventsubtype: this.findEventSubType(row.eventsubtype) || 'не определено',
            additionallinks: this.parseAdditionalLinks(row.additionallinks) || [],
            members: this.parseMembers(row.members) || [],
            organizationform: this.findOrganizationForm(row.organizationform) || 'не определено', // Исправлено
            responsible: row.responsible || 'не определено',
            addtype: this.getAddType('Из файла'),
            responsiblelogin: this.parseOrganizerEmails(row.responsiblelogin) || [] // Добавляем email организаторов
          };

          // Валидация данных
          if (!this.validateEventData(eventData)) {
            console.error('Ошибка валидации данных:', eventData);
            continue; // Пропускаем эту строку, если данные не прошли валидацию
          }

          console.log('Event Data:', eventData); // Добавлено для отладки

          try {
            await this.addevent(eventData);
            console.log('Мероприятие добавлено:', eventData);
          } catch (error) {
            console.error('Ошибка при добавлении мероприятия:', error);
          }
        }

        window.alert('Мероприятия добавлены');
        this.getallevents();
      };
      reader.readAsArrayBuffer(file);
    },

    validateEventData(eventData) {
      return eventData.name && eventData.date && eventData.timestart && eventData.timeend && eventData.description && eventData.imagename &&
        eventData.organizator && eventData.location && eventData.eventclass && eventData.eventtype &&
        eventData.level && eventData.eventsubtype && eventData.members.length > 0 && eventData.organizationform &&
        eventData.responsible && eventData.addtype && eventData.responsiblelogin.length > 0; // Добавляем проверку на наличие хотя бы одного email организатора
    },
    findOrganizator(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const organizator = this.organizators.find(org => org.fullname === formattedName);
      return organizator ? organizator.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    },
    findEventClass(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventClass = this.EventClasses.find(cls => cls.name === formattedName);
      return eventClass ? eventClass.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    },
    findEventType(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventType = this.EventTypes.find(type => type.name === formattedName);
      return eventType ? eventType.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    },
    findEventLevel(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventLevel = this.Levels.find(lvl => lvl.name === formattedName);
      return eventLevel ? eventLevel.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    },
    findEventSubType(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const eventSubType = this.EventSubTypes.find(subType => subType.name === formattedName);
      return eventSubType ? eventSubType.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    },
    findOrganizationForm(name) {
      const formattedName = name.charAt(0).toUpperCase() + name.slice(1);
      const organizationForm = this.OrganizationForms.find(form => form.name === formattedName);
      return organizationForm ? organizationForm.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    },
    parseAdditionalLinks(links) {
      if (!links) return [];
      return links.split(',').map(link => link.trim());
    },
    parseSuborganizators(suborganizators) {
      if (!suborganizators) return [];
      return suborganizators.split(',').map(name => {
        const formattedName = name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
        const organizator = this.organizators.find(org => org.fullname === formattedName);
        return organizator ? organizator.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
      });
    },
    parseMembers(members) {
      if (!members) return [];
      return members.split(',').map(name => {
        const formattedName = name.trim().charAt(0).toUpperCase() + name.trim().slice(1);
        const member = this.Members.find(mem => mem.name === formattedName);
        return member ? member.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
      });
    },
    parseOrganizerEmails(emails) {
      if (!emails) return [];
      return emails.split(',').map(email => email.trim());
    },
    getAddType(method) {
      const addType = this.addTypes.find(type => type.name === method);
      return addType ? addType.uid : '00'; // Предполагаем, что '00' - это uid для "Не определено"
    }
  },
  async created() {
    this.loading = true;
    await Promise.all([
      this.getorganizators(),
      this.getaddtypes(),
      this.geteventclasses(),
      this.geteventtypes(),
      this.getlevels(),
      this.geteventsubtypes(),
      this.getmembers(),
      this.getorganizationforms()
    ]);
    this.loading = false;
  }
}
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.home {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.event-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
}

.form-btn {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.form-btn:hover {
  background-color: #ef7d00;
}

.file-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #f8f8f8;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.file-input::-webkit-file-upload-button {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.file-input::-webkit-file-upload-button:hover {
  background-color: #3c218c;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
  background: #3c218c;
  color: #FFFFFF;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #FFFFFF;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-green {
  color: white;
  background: #3c218c;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  cursor: pointer;
}

.btn-add,
.btn-remove {
  padding: 10px;
  background-color: #3c218c;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
}

.btn-add:hover,
.btn-remove:hover {
  background-color: #ef7d00;
}

/* .btn-add::after {
  content: " ссылки";
} */
</style>