<template>
  <nav class="main-nav">
    <div class="left-part">
      <img src="../assets/NavBarLogo.png" alt="Logo" class="logo circle" @click="goToEventsView">
    </div>
    <div class="center-part" v-if="$route.path === '/EventsView'">
      <h2>Мероприятия</h2>
    </div>
    <div class="center-part" v-if="$route.path === '/favorite'">
      <h2>Избранное</h2>
    </div>
    <div class="center-part" v-if="$route.path === '/about'">
      <h2>Личный кабинет</h2>
    </div>
    <div class="right-part">
      <router-link to="/EventsView" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/EventsView' }">
        <i class="fas fa-calendar-alt"></i>
      </router-link>
      <router-link to="/favorite" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/favorite' }">
        <i class="fas fa-heart"></i>
      </router-link>
      <router-link to="/about" class="dropdown-button" v-bind:class="{ 'active-button': $route.path === '/about' }">
        <i class="fas fa-user"></i>
      </router-link>
      <button class="dropdown-button" @click="logout">
        <i class="fas fa-sign-out-alt"></i>
      </button>
    </div>
  </nav>
</template>

<script>
import router from '@/router';
import { mapActions } from 'vuex';

export default {
  methods: {
    goToEventsView() {
      this.$router.push('/EventsView')
    },
    ...mapActions('user', ['logoutAction']),
    async logout() {
      try {
        await this.logoutAction();
        localStorage.clear();
        this.$store.commit('user/setUser', null);
        this.$router.push('/');
        window.alert('Вы вышли из аккаунта');
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>
.main-nav {
  background-color: #3c218c;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box; /* Добавлено */
}

.circle {
  border-radius: 50%;
  width: 100%; /* Добавлено */
  height: auto; /* Добавлено */
}

.left-part {
  display: flex;
  align-items: center;
  max-width: 10%;
  max-height: auto;
  padding-left: 10px;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.5s ease;
  margin-left: 30px;
  border-radius: 20px;
  box-sizing: border-box; /* Добавлено */
}

.logo {
  max-width: 50%;
  max-height: auto;
  background-color: aliceblue;
  cursor: pointer;
  box-sizing: border-box; /* Добавлено */
}

.center-part {
  display: flex;
  align-items: center;
  position: relative;
  width: 30%;
  justify-content: center;
  box-sizing: border-box; /* Добавлено */
}

.right-part {
  display: flex;
  padding-right: 30px;
  margin-right: 30px;
  box-sizing: border-box; /* Добавлено */
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  border-style: hidden;
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Добавлено transition для background-color */
  box-shadow: 0 0 0 1px white;
  width: 30px;
  height: 30px;
  background-color: #3c218c; /* Добавлено background-color */
  box-sizing: border-box; /* Добавлено */
}

.dropdown-button:hover {
  box-shadow: 0 0 0 1px #ef7d00;
  color: #ef7d00;
  background-color: #3c218c;
}

.active-button {
  color: #ef7d00;
  box-shadow: 0 0 0 1px #ef7d00;
}

h2 {
  color: aliceblue;
}
</style>