<template>
  <div class="content">
    <div class="home">
      <h2>Вход</h2>
      <form @submit.prevent="go_login">
        <p>
      Если для регистрации Вы использовали учетную запись Microsoft, введите логин
      в формате xxxxxx@pspu.ru и пароль для aFishka
      <br>
      Если для регистрации Вы использовали обычную регистрацию, введите логин и
      пароль для aFishka
    </p>
        <input type="email" class="form-input" placeholder="Введите email" v-model="email">
        <input type="password" class="form-input" placeholder="Введите пароль" v-model="password">
        <button type="submit" class="form-btn">Войти</button>
        <span>Нет аккаунта? <router-link :to="{ path: '/register' }">Зарегистрироваться</router-link></span>
      </form>
    </div>
  </div><customfooter/>
  
</template>

<script>
import { mapActions } from 'vuex';
import customfooter from "@/components/footer.vue";
// import navbarauth from "@/components/navbarauth.vue";
import MsLoginButton from "@/components/MsLoginButton.vue";
import { mapMutations } from 'vuex';

export default {
    name: 'loginpage',
    data() {
        return {
            email: '',
            password: '',
            account: undefined,
            containers: []
        }
    },
    created() {
        console.log('created in page')
        console.log('VUE_APP_SERVER:', process.env.VUE_APP_SERVER);
        this.$emitter.on(
            'login',
            async function (account) {
                this.account = account;
                console.log(this.account);
                console.log('Getting storage data');
            }.bind(this),
        ),
            this.$emitter.on('logout', () => {
                console.log('logging out');
                this.account = undefined;
            });
    },
    methods: {
        ...mapMutations(['setAccessToken']),
        ...mapActions({
            login: 'auth/login'
        }),
        go_login() {
            const formData = {
                email: this.email,
                password: this.password
            }
            this.login(formData)
        }

    },
    components: {
        MsLoginButton,
        // navbarauth,
        customfooter
    },
}
</script>

<style scoped>
.content p,
.content h2 {
  max-width: 500px; /* Adjust this value as needed */
  margin: 0 auto; /* Center the text horizontally */
  padding: 15px;
  text-align: justify;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Added vertical centering */
  align-items: center;
  height: 100vh;
  background-color: #fff; /* Set background color to white */
}

form {
  display: flex;
  padding: 10px; /* Reduced padding for mobile */
  flex-direction: column;
  row-gap: 20px;
  border: 1px solid #ef7d00;
  border-radius: 20px;
  background-color: #fff;
}

.content {
  margin-top: 70px;
  text-align: center; /* Text already centered horizontally */
  display: flex; /* Added for vertical centering */
  flex-direction: column; /* Added for vertical centering */
  justify-content: center; /* Center content vertically within the .content div */
  height: 100vh; /* Make the content fill the viewport height for full-screen centering */
}

h2 {
  color: #000000; /* Text color to violet */
  font-size: 2em;
}

.form-input {
  padding: 15px; /* Increased padding for better readability */
  margin: 0;
  border: none; /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Set input background to light gray */
}

.form-btn {
  background-color: #ef7d00; /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto; /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c; /* Change background color on hover to violet */
}

a {
  color: #3c218c; /* Link color to violet */
  text-decoration: none;
}

a:hover {
  color: #ef7d00; /* Change link color on hover to orange */
}
</style>