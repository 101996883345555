<template>
  <button @click="SignIn" class="form-btn">Регистрация через Microsoft</button>
  <p>Регистрация Microsoft только для студентов, преподавателей и сотрудников ПГГПУ, используйте коропоративный аккаунт. Например 123456@pspu.ru</p>
</template>

<script>
import { PublicClientApplication } from '@azure/msal-browser';
import { getUserProfile } from '@/services/msGraphService';
export default {
  name: 'MsLoginButton',
  data() {
    return {
      account: undefined,
    };
  },
  async created() {
    console.log ('created in button')
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig,
    );
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit('login', this.account);
  },
  methods: {
    async SignIn() {
  await this.$msalInstance.initialize();

  try {
    // Запрос интерактивного согласия
    const response = await this.$msalInstance.loginPopup({
      scopes: ['User.Read'],
    });

    // Получение токена доступа
    const accessToken = response.accessToken;

    // Получение профиля пользователя
    const userProfile = await getUserProfile(accessToken);

    // Обновление данных о пользователе в компоненте
    this.account = userProfile;

    // Эмиссия события для уведомления других компонентов о том, что пользователь вошел в систему
    this.$emitter.emit('login', this.account);

    // Перенаправление пользователя на домашнюю страницу
    this.$router.push('/DataAddforMS');
  } catch (error) {
    console.error(error);
  }
},

  },
};
</script>
<style scoped>
p {
  text-align: justify;
}
.form-btn {
  background-color: #3c218c; /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto; /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #ef7d00; /* Change background color on hover to violet */
}
</style>