<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <div class="header">
            Сканирование QR-кода
          </div>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div id="qr-reader" style="width: 100%"></div>
            <div v-if="scannedData">
              <p>Информация из QR-кода:</p>
              <pre>{{ scannedData }}</pre>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer">
            <button v-if="scannedData" type="button" class="btn-green" @click="confirmScan" aria-label="Confirm scan">
              Подтвердить
            </button>
            <button type="button" class="btn-red" @click="closeModal" aria-label="Close modal">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'QrScannerModal',
  computed: {
    ...mapState('user', ['user']),
  },
  data() {
    return {
      scannedData: null,
      html5QrcodeScanner: null
    };
  },
  mounted() {
    this.html5QrcodeScanner = new Html5QrcodeScanner(
      "qr-reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      /* verbose= */ false
    );
    this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
  },
  beforeDestroy() {
    this.html5QrcodeScanner.clear();
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    onScanSuccess(decodedText, decodedResult) {
      this.scannedData = decodedText;
      this.sendAttendanceData(decodedText);
    },
    onScanFailure(error) {
      // handle scan failure, usually better to ignore and keep scanning
      console.warn(`Code scan error = ${error}`);
    },
    confirmScan() {
      this.scannedData = null;
      this.html5QrcodeScanner.clear();
      this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
    },
    async sendAttendanceData(data) {
      try {
        const parsedData = JSON.parse(data);
        await this.addAttendance({
          useruid: parsedData.useruid,
          scanuid: this.user.uid, // Используем переданный useruid
          eventuid: parsedData.eventuid,
          eventrole: parsedData.eventrole
        });
      } catch (error) {
        console.error('Error sending attendance data:', error);
      }
    },
    ...mapActions({
      addAttendance: 'attendance/addAttendance',
      getUserByUid: 'user/getUserByUid'
    })
  }
};
</script>
<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 500px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

.btn-red {
  color: white;
  background: #FF4136;
  border: 1px solid #FF4136;
  border-radius: 2px;
  margin-left: 10px;
}
</style>