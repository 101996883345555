<template>
        <div class="content">
    <form1>
        <div v-if="account">
            <h2>Уточните ваши данные для aFishka</h2>
            <div class="level">
                <p> Вы вошли как: {{ account.username }}</p>
                <p> Фамилия: {{ mssurname }}</p>
                <p> Имя: {{ msname }}</p>
                <p> Отчество: {{ msthirdname }}</p>
                <p> Учебное заведение: ПГГПУ</p>
                <p> Ваш логин для входа: {{ account.username }}</p>
            </div>
        </div>
        <button @click="logout" class="form-btn">Вернуться на страницу регистрации</button>
    </form1>
        <div class="DataAddforMS">
            <form @submit.prevent="go_register()">
                <p>Придумайте пароль для aFishka, в дальнейшем ваш логин Microsoft и пароль для aFishka будут использоваться для входа в систему</p>
                <input type="password" class="form-input" placeholder="Пароль для aFishka" required
                    minlength="8" maxlength="40" v-model="password">
                <input type="password" class="form-input" placeholder="Подтвердите пароль" required minlength="8"
                    maxlength="40" v-model="confirmPassword">
                <select type="rolein" class="form-input" placeholder="Роль (студент, преподаватель, сотрудник)" required
                    v-model="rolein">
                    <option value="" disabled selected>Роль (студент, преподаватель, сотрудник)</option>
                    <option value="Студент">Студент</option>
                    <option value="Преподаватель">Преподаватель</option>
                    <option value="Сотрудник">Сотрудник</option>

                </select>
                <select type="faculty" v-model="faculty" class="form-input">
                    <option value="" disabled selected>Факультет</option>
                    <option value="МФ">МФ</option>
                    <option value="ФФ">ФФ</option>
                    <option value="ФФК">ФФК</option>
                    <option value="ПиМНО">ПиМНО</option>
                    <option value="ППД">ППД</option>
                    <option value="ЕНФ">ЕНФ</option>
                    <option value="ИнЯз">ИнЯз</option>
                    <option value="ФизФ">ФизФ</option>
                    <option value="ИстФ">ИстФ</option>
                    <option value="МузФ">МузФ</option>
                    <option value="ПсФ">ПсФ</option>
                    <option value="ИНЭК">ИНЭК</option>
                    <option value="ПиСПО">ПиСПО</option>
                </select>
                <input type="user_group" class="form-input" placeholder="Группа" v-model="user_group">
                <button type="submit" class="form-btn">Продолжить</button>

            </form>
        </div>
</div>
<!-- <customfooter/> -->
</template>
<script>
import { mapActions } from 'vuex';
import { PublicClientApplication } from '@azure/msal-browser';
import customfooter from "@/components/footer.vue";

export default {
    data() {
        return {
            account: undefined,
            email: '',
            password: '',
            surname: '',
            name: '',
            thirdname: '',
            authType:'',
            rolein: '',
            organization: '',
            faculty: '',
            user_group: '',
            security:'',
            country: '',
        };
    },
    async created() {
        console.log('created in button')
        this.$msalInstance = new PublicClientApplication(
            this.$store.state.msalConfig,
        );
    },
    mounted() {
        const accounts = this.$msalInstance.getAllAccounts();
        if (accounts.length == 0) {
            return;
        }
        this.account = accounts[0];
        this.$emitter.emit('login', this.account);
        const [mssurname, msname, msthirdname] = this.account.name.split(" ");
        this.mssurname = mssurname;
        this.msname = msname;
        this.msthirdname = msthirdname;
    },
    computed: {

    },
    components: {customfooter,},
    methods: {
        ...mapActions({
            register: 'auth/register',
            logoutAction: 'user/logout'
        }),
        go_register() {
            if (this.password !== this.confirmPassword) {
                alert('Пароли не совпадают');
                return;
            }
            const formData = {
                email: this.account.username,
                password: this.password,
                surname: this.mssurname,
                name: this.msname,
                thirdname: this.msthirdname,
                rolein: this.rolein,
                organization: "ПГГПУ",
                faculty: this.faculty || "Не указано",
                user_group: this.user_group || "Не указано",
                authType: "MS",
                security: "defaultuser",
                country:'Россия'
            }
console.log(formData);
            this.register(formData)
        },
        async logout() {
            try {
                await this.logoutAction();
                localStorage.clear();
                this.$store.commit('user/setUser', null);
                this.$router.push('/register'); // Перенаправляет пользователя на страницу авторизации
                window.alert('Вы прервали процесс регистрации');
            } catch (error) {
                console.log(error);
            }
        },
    },
}
</script>
<style scoped>
.content p,
.content h2 {
  max-width: 500px; /* Adjust this value as needed */
  margin: 0 auto; /* Center the text horizontally */
  padding: 15px;
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Added vertical centering */
  align-items: center;
  height: 100vh;
  background-color: #fff; /* Set background color to white */
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Add a subtle background gradient */
  background: linear-gradient(to right, #f0f0f0, #e0e0e0);
  height: 80vw
}

.form-title {
  color: #000000; /* Text color to violet */
  font-size: 2em;
  margin-bottom: 20px;
}

form1,
form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* Set the width of the forms */
  width: 600px;
  row-gap: 15px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #fff; /* Set form background to white */
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  width: 100%;
}

.form-btn {
  background-color: #ef7d00; /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto; /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c; /* Change background color on hover to violet */
}

a {
  color: #3c218c; /* Link color to violet */
  text-decoration: none;
}

a:hover {
  color: #ef7d00; /* Change link color on hover to orange */
}

h2 {
  color: #000000; /* Text color to violet */
  font-size: 2em;
}

.form-input {
  padding: 15px; /* Increased padding for better readability */
  margin: 0;
  border: none; /* Removed border for input fields */
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f5f5f5; /* Set input background to light gray */
}

.form-btn {
  background-color: #ef7d00; /* Button background to orange */
  color: white;
  padding: 15px 30px;
  border: none;
  cursor: pointer;
  margin: 10px auto; /* Center the button horizontally */
  border-radius: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.form-btn:hover {
  background-color: #3c218c; /* Change background color on hover to violet */
}

a {
  color: #3c218c; /* Link color to violet */
  text-decoration: none;
}

a:hover {
  color: #ef7d00; /* Change link color on hover to orange */
}
</style>