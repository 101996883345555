import axios from 'axios';

const state = {
  // Здесь можно добавить состояние, если оно вам нужно
};

const getters = {
  // Здесь можно добавить геттеры, если они вам нужны
};

const mutations = {
  // Здесь можно добавить мутации, если они вам нужны
};

const actions = {
  async submitEventRegistration({ commit }, formData) {
    try {
      const response = await axios.post(`${process.env.VUE_APP_SERVER}/api/eventregistration/registrations`, formData);
      if (response.status === 201) {
        return { success: true, message: 'Регистрация успешно создана!' };
      } else {
        return { success: false, message: 'Ошибка при создании регистрации' };
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      return { success: false, message: 'Ошибка при отправке формы' };
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};