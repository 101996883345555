<template>
  <div>
    <navbar />
    <div v-if="events" class="content">
      <div class="search-filters">
        <div class="search-container">
          <input v-model="searchQuery" placeholder="Поиск по названию ..." class="search-input" />
          <i class="fas fa-search search-icon"></i>
        </div>
      </div>
      <div v-if="user">
        <h2>Сегодня</h2>
        <div v-if="todayEvents.length > 0" class="events-grid">
          <EventCard v-for="event in todayEvents" :key="event.id" :event="event" :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
        </div>
        <div v-else>
          <p>На сегодня мероприятия не запланированы</p>
        </div>

        <h2>Ближайшие мероприятия</h2>
        <div v-if="upcomingEvents.length > 0" class="events-grid">
          <EventCard v-for="event in upcomingEvents" :key="event.id" :event="event" :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
        </div>
        <div v-else>
          <p>Ближайших мероприятий нет</p>
        </div>
        <h2>Все мероприятия</h2>
        
        <div v-for="(events, month) in allEventsByMonth" :key="month">
          <h3>{{ month }}</h3>
          <div v-if="events.length > 0" class="events-grid">
            <EventCard v-for="event in events" :key="event.id" :event="event" :getEventColumnValue="getEventColumnValue" @show-modal="showModal" />
          </div>
          <div v-else>
            <p>Нет мероприятий в этом месяце</p>
          </div>
        </div>
        <modal 
          v-if="isModalVisible" 
          @close="closeModal" 
          :event="selectedEvent" 
          :user="selectedUser" 
          :fedDistricts="fedDistricts"
          :academicDegrees="academicDegrees"
          :academicTitles="academicTitles"
          :organizationTypes="organizationTypes"
          :participationForms="participationForms"
        />
      </div>
      <div v-else>
        <p>Загрузка данных...</p>
        <p>Если вы не авторизованы, перейдите на страницу авторизации</p>
        <router-link to="/">Перейти к странице авторизации</router-link>
      </div>
    </div>
    <div v-else>
      Loading event data...
    </div>
    <customfooter />
  </div>
</template>

<script>
import EventCard from '@/components/EventCard.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import customfooter from "@/components/footer.vue";
import modal from '@/components/ModalQR.vue';
import moment from 'moment';

export default {
  name: 'EventsView',
  components: {
    navbar,
    EventCard,
    modal,
    customfooter,
  },
  data() {
    return {
      isModalVisible: false,
      selectedEvent: null,
      selectedUser: null,
      searchQuery: '',
      dateRange: [],
    };
  },
  computed: {
    ...mapState({
      events: state => state.event.events,
      organizators: state => state.references.organizators,
      addTypes: state => state.references.addTypes,
      EventClasses: state => state.references.EventClasses,
      EventTypes: state => state.references.EventTypes,
      Levels: state => state.references.Levels,
      EventSubTypes: state => state.references.EventSubTypes,
      Members: state => state.references.Members,
      OrganizationForms: state => state.references.OrganizationForms
    }),
    ...mapGetters({
      user: 'user/user'
    }),
    filteredEvents() {
      return this.events.filter(event => {
        const searchMatch = event.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                            event.description?.toLowerCase().includes(this.searchQuery.toLowerCase());
        const dateMatch = this.dateRange.length ? moment(event.date).isBetween(this.dateRange[0], this.dateRange[1], 'day', '[]') : true;
        return searchMatch && dateMatch;
      });
    },
    todayEvents() {
      return this.filteredEvents.filter(event => moment(event.date).isSame(moment(), 'day'));
    },
    upcomingEvents() {
      const today = new Date();
      return this.filteredEvents
        .filter(event => moment(event.date).isAfter(moment(today)))
        .sort((a, b) => moment(a.date).diff(moment(b.date)))
        .slice(0, 5);
    },
    allEventsByMonth() {
      const eventsByMonth = this.filteredEvents.reduce((acc, event) => {
        const month = moment(event.date).format('MMMM');
        const monthName = month.charAt(0).toUpperCase() + month.slice(1);
        if (!acc[monthName]) {
          acc[monthName] = [];
        }
        acc[monthName].push(event);
        return acc;
      }, {});
      const sortedMonths = Object.keys(eventsByMonth).sort((a, b) => {
        return moment(a, 'MMMM').diff(moment(b, 'MMMM'));
      });
      const sortedEventsByMonth = {};
      sortedMonths.forEach(month => {
        sortedEventsByMonth[month] = eventsByMonth[month].sort((a, b) => {
          return moment(a.date).diff(moment(b.date));
        });
      });
      return sortedEventsByMonth;
    }
  },
  methods: {
    ...mapActions({
      getUserByUid: 'user/getUserByUid',
      getAllEvents: 'event/getallevents',
      getorganizators: 'references/getorganizators',
      getaddtypes: 'references/getaddtypes',
      geteventclasses: 'references/geteventclasses',
      geteventtypes: 'references/geteventtypes',
      getlevels: 'references/getlevels',
      geteventsubtypes: 'references/geteventsubtypes',
      getmembers: 'references/getmembers',
      getorganizationforms: 'references/getorganizationforms'
    }),
    showModal(data) {
      this.selectedEvent = data.event;
      this.selectedUser = data.user;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedEvent = null;
      this.selectedUser = null;
    },
    getEventColumnValue(col, event) {
      switch (col) {
        case 'organizator':
          return this.organizators.find(org => org.uid === event[col])?.fullname || 'Неизвестный организатор';
        case 'suborganizator':
          return event[col]?.map(uid => this.organizators.find(org => org.uid === uid)?.fullname || 'Неизвестный организатор').join(', ') || 'Нет соорганизаторов';
        case 'addtype':
          return this.addTypes.find(type => type.uid === event[col])?.name || 'Неизвестный тип добавления';
        case 'eventclass':
          return this.EventClasses.find(cls => cls.uid === event[col])?.name || 'Неизвестный класс мероприятия';
        case 'eventtype':
          return this.EventTypes.find(type => type.uid === event[col])?.name || 'Неизвестный тип мероприятия';
        case 'level':
          return this.Levels.find(level => level.uid === event[col])?.name || 'Неизвестный уровень мероприятия';
        case 'eventsubtype':
          return event[col]?.map(uid => this.EventSubTypes.find(subtype => subtype.uid === uid)?.name || 'Неизвестный подтип').join(', ') || 'Нет подтипов';
        case 'members':
          return event[col]?.map(uid => this.Members.find(member => member.uid === uid)?.name || 'Неизвестная целевая аудитория').join(', ') || 'Нет целевой аудитории';
        case 'organizationform':
          return this.OrganizationForms.find(form => form.uid === event[col])?.name || 'Неизвестная форма организации';
        default:
          return event[col];
      }
    },
    customDateFormatter(date) {
      return moment(date).format('DD.MM.YYYY');
    }
  },
  async beforeMount() {
    await this.getUserByUid();
    await this.getAllEvents();
    await Promise.all([
      this.getorganizators(),
      this.getaddtypes(),
      this.geteventclasses(),
      this.geteventtypes(),
      this.getlevels(),
      this.geteventsubtypes(),
      this.getmembers(),
      this.getorganizationforms()
    ]);
  }
}
</script>

<style scoped>
.events-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  grid-gap: 24px;
  padding-inline: 70px;
}

@media screen and (max-width: 1450px) {
  .events-grid  {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 900px) {
  .events-grid  {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

.content {
  margin-top: 70px;
  /* Отступ сверху для основного контента */
}

.events-grid h2 {
  grid-column: 1 / -1;
  /* Занимает всю ширину */
}

h2, h3 {
  text-align: left;
  padding: 10px;
  padding-left: 70px;
}

/* Стили для поля поиска */
.search-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 25px; /* Закругленные углы */
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Плавные переходы */
}

.search-input:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}

.search-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 18px;
  pointer-events: none; /* Иконка не будет реагировать на клики */
}
</style>