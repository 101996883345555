<template>
  <div>
    <navbar />
    <div class="content">
    <div v-if="user">
      <div class="favorite-events">
        <div class="events-grid">
          <eventCard v-for="event in favoriteEvents" :key="event.uid" :event="event" @show-modal="showModal"/>
          <modal v-if="isModalVisible" @close="closeModal" :event="selectedEvent" />
        </div>
      </div>
    </div>
    <div v-else>
      <p>Загрузка данных...</p>
      <p>Если вы не авторизованы, перейдите на страницу авторизации</p>
      <router-link to="/">Перейти к странице авторизации</router-link>
    </div>
  </div>
</div>
<customfooter/>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import navbar from "@/components/navbar.vue";
import customfooter from "@/components/footer.vue";
import eventCard from "@/components/EventCard.vue"; // Предполагается, что у вас есть компонент eventCard
import modal from '@/components/ModalQR.vue';
export default {
  name: 'Favorite',

  components: {
    navbar,
    eventCard,
    customfooter,
    modal
  },
  data() {
    return {
      showAddEventForm: false,
      isModalVisible: false,
      selectedEvent: null
    };
  },
  computed: {
  ...mapState('user', [
    'user',
    'favoriteEvents'
  ]),
  user() {
    return this.$store.state.user.user;
  },
  events() {
    return this.$store.state.event.events;
  },
},

  methods: {
    ...mapActions('user', [
      'getUserByUid',
      'getFavoriteEvents'
    ]),
    showModal(event) {
      this.selectedEvent = event;
      this.isModalVisible = true;
      console.log("open modal");
    },
    closeModal() {
      this.isModalVisible = false;
      this.selectedEvent = null;
    },
  },

  beforeMount() {
    this.getUserByUid();
    this.getFavoriteEvents();
  },
  watch: {
    favoriteEvents: {
    handler(newVal) {
      // Обновляем список избранных мероприятий
      this.favoriteEvents = newVal;
    },
    deep: true
  }
},
}
</script>

<style scoped>
.events-grid {
  display: grid;
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  grid-gap: 24px;
  padding-inline: 70px;
}

@media screen and (max-width: 1460px) {
  .events-grid  {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media screen and (max-width: 900px) {
  .events-grid  {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

.content {
    margin-top: 70px; /* Отступ сверху для основного контента */
  }
</style>
