<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModal">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop>
        <div class="modal-header" id="modalTitle">
          <div class="header">
            Регистрация участника на мероприятие: {{ event.name }}
          </div>
        </div>
        <div class="modal-body" id="modalDescription">
          <div class="body">
            <div class="home">
              <form @submit.prevent="submitForm" class="event-form">
                <!-- Фамилия, Имя, Отчество -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="lastName">
                      <i class="fas fa-user fa-sm"></i> Фамилия
                    </label>
                    <input type="text" id="lastName" name="lastName" class="form-input" placeholder="Фамилия" :required="true" v-model="formData.lastName" readonly>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="firstName">
                      <i class="fas fa-user fa-sm"></i> Имя
                    </label>
                    <input type="text" id="firstName" name="firstName" class="form-input" placeholder="Имя" :required="true" v-model="formData.firstName" readonly>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="middleName">
                      <i class="fas fa-user fa-sm"></i> Отчество
                    </label>
                    <input type="text" id="middleName" name="middleName" class="form-input" placeholder="Отчество" :required="true" v-model="formData.middleName" readonly>
                  </div>
                </div>

                <!-- Тип организации -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="organizationType">
                      <i class="fas fa-building fa-sm"></i> Тип организации
                    </label>
                    <select id="organizationType" name="organizationType" class="form-input" :required="true" v-model="formData.organizationType" @change="handleOrganizationTypeChange">
                      <option value="" disabled>Выберите...</option>
                      <option v-for="type in organizationTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
                    </select>
                  </div>
                </div>

                <!-- Укажите тип организации -->
                <div v-if="showOrganizationTypeInput" class="form-row">
                  <div class="form-group">
                    <label for="customOrganizationType">
                      <i class="fas fa-pencil-alt fa-sm"></i> Укажите тип организации
                    </label>
                    <input type="text" id="customOrganizationType" name="customOrganizationType" class="form-input" placeholder="Укажите тип организации" :required="showOrganizationTypeInput" v-model="formData.customOrganizationType">
                  </div>
                </div>

                <!-- Полное наименование организации -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="organizationName">
                      <i class="fas fa-building fa-sm"></i> Полное наименование организации
                    </label>
                    <input type="text" id="organizationName" name="organizationName" class="form-input" placeholder="Наименование организации" :required="true" v-model="formData.organizationName">
                  </div>
                </div>

                <!-- Вид деятельности в организации -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="activityType">
                      <i class="fas fa-briefcase fa-sm"></i> Вид деятельности в организации
                    </label>
                    <select id="activityType" name="activityType" class="form-input" :required="true" v-model="formData.activityType" @change="handleActivityTypeChange">
                      <option value="" disabled>Выберите...</option>
                      <option value="student">Студент</option>
                      <option value="teacher">Преподаватель</option>
                      <option value="other">Другое (указать!)</option>
                    </select>
                  </div>
                </div>

                <!-- Дополнительные поля для студента -->
                <div v-if="formData.activityType === 'student'" class="form-row">
                  <div class="form-group">
                    <label for="educationLevel">
                      <i class="fas fa-graduation-cap fa-sm"></i> Уровень образования
                    </label>
                    <select id="educationLevel" name="educationLevel" class="form-input" :required="formData.activityType === 'student'" v-model="formData.educationLevel">
                      <option value="" disabled>Выберите...</option>
                      <option value="bachelor">Бакалавр</option>
                      <option value="master">Магистр</option>
                      <option value="aspirant">Аспирант</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="course">
                      <i class="fas fa-book fa-sm"></i> Курс
                    </label>
                    <select id="course" name="course" class="form-input" :required="formData.activityType === 'student'" v-model="formData.course">
                      <option value="" disabled>Выберите...</option>
                      <option v-for="n in 5" :key="n" :value="n">{{ n }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="studyDirection">
                      <i class="fas fa-book fa-sm"></i> Направление обучения
                    </label>
                    <select id="studyDirection" name="studyDirection" class="form-input" :required="formData.activityType === 'student'" v-model="formData.studyDirection">
                      <option value="" disabled>Выберите...</option>
                      <option v-for="direction in studyDirections" :key="direction.code" :value="direction.code">{{ direction.name }}</option>
                    </select>
                  </div>
                </div>

                <!-- Дополнительные поля для преподавателя -->
                <div v-if="formData.activityType === 'teacher'">
                  <div class="form-row">
                    <div class="form-group">
                      <label for="academicDegree">
                        <i class="fas fa-graduation-cap fa-sm"></i> Ученая степень
                      </label>
                      <select id="academicDegree" name="academicDegree" class="form-input" :required="formData.activityType === 'teacher'" v-model="formData.academicDegree">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="degree in academicDegrees" :key="degree.id" :value="degree.id">{{ degree.name }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label for="academicTitle">
                        <i class="fas fa-graduation-cap fa-sm"></i> Ученое звание
                      </label>
                      <select id="academicTitle" name="academicTitle" class="form-input" :required="formData.activityType === 'teacher'" v-model="formData.academicTitle">
                        <option value="" disabled>Выберите...</option>
                        <option v-for="title in academicTitles" :key="title.id" :value="title.id">{{ title.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- Дополнительные поля для "Другое" -->
                <div v-if="formData.activityType === 'other'" class="form-row">
                  <div class="form-group">
                    <label for="otherActivityType">
                      <i class="fas fa-pencil-alt fa-sm"></i> Укажите вид деятельности
                    </label>
                    <input type="text" id="otherActivityType" name="otherActivityType" class="form-input" placeholder="Укажите вид деятельности" :required="formData.activityType === 'other'" v-model="formData.otherActivityType">
                  </div>
                </div>

                <!-- Страна, Федеральный округ, Регион, Населенный пункт -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="country">
                      <i class="fas fa-globe fa-sm"></i> Страна
                    </label>
                    <input type="text" id="country" name="country" class="form-input" placeholder="Страна" :required="true" v-model="formData.country" readonly>
                  </div>
                  <div class="form-group">
                    <label for="fedDistrict">
                      <i class="fas fa-map fa-sm"></i> Федеральный округ
                    </label>
                    <select id="fedDistrict" name="fedDistrict" class="form-input" :required="true" v-model="formData.fedDistrict" @change="loadRegions">
                      <option value="" disabled>Выберите...</option>
                      <option v-for="district in filteredFedDistricts" :key="district.id" :value="district.id">{{ district.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="region">
                      <i class="fas fa-map-marker-alt fa-sm"></i> Регион
                    </label>
                    <select id="region" name="region" class="form-input" :required="formData.fedDistrict" v-model="formData.region" :disabled="!formData.fedDistrict">
                      <option value="" disabled>Выберите...</option>
                      <option v-for="reg in regions" :key="reg.id" :value="reg.id">{{ reg.name }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="city">
                      <i class="fas fa-city fa-sm"></i> Населенный пункт
                    </label>
                    <input type="text" id="city" name="city" class="form-input" :required="formData.region" placeholder="Населенный пункт" v-model="formData.city" :disabled="!formData.region">
                  </div>
                </div>

                <!-- Телефон, Почта -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="phone">
                      <i class="fas fa-phone fa-sm"></i> Телефон
                    </label>
                    <input type="tel" id="phone" name="phone" class="form-input" placeholder="Телефон" :required="true" v-model="formData.phone" @input="formatPhone">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="email">
                      <i class="fas fa-envelope fa-sm"></i> Почта
                    </label>
                    <input type="email" id="email" name="email" class="form-input" placeholder="Почта" :required="true" v-model="formData.email" readonly>
                  </div>
                </div>

                <!-- Форма участия в мероприятии -->
                <div class="form-row">
                  <div class="form-group">
                    <label for="participationForm">
                      <i class="fas fa-users fa-sm"></i> Форма участия в мероприятии
                    </label>
                    <select id="participationForm" name="participationForm" class="form-input" :required="true" v-model="formData.participationForm" @change="handleParticipationFormChange">
                      <option value="" disabled>Выберите...</option>
                      <option v-for="form in participationForms" :key="form.id" :value="form.id">{{ form.name }}</option>
                    </select>
                  </div>
                </div>

                <!-- Тема доклада -->
                <div v-if="showReportTopic" class="form-row">
                  <div class="form-group">
                    <label for="reportTopic">
                      <i class="fas fa-file-alt fa-sm"></i> Тема доклада
                    </label>
                    <input type="text" id="reportTopic" name="reportTopic" class="form-input" placeholder="Тема доклада" :required="showReportTopic" v-model="formData.reportTopic">
                  </div>
                </div>

                <!-- Согласие с обработкой персональных данных -->
                <div class="form-row">
                  <div class="form-group checkbox-container">
                    <input type="checkbox" id="agree" name="agree" :required="true">
                    <label for="agree">Согласен с обработкой персональных данных</label>
                  </div>
                </div>

                <button type="submit" class="form-btn">Зарегистрироваться</button>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="footer">
            <button type="button" class="btn-green" @click="closeModal" aria-label="Close modal">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="fade">
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
      <p>Идет загрузка данных...</p>
    </div>
  </transition>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formData: {
        lastName: '',
        firstName: '',
        middleName: '',
        activityType: '',
        educationLevel: '',
        course: '',
        studyDirection: '',
        otherActivityType: '',
        country: '',
        fedDistrict: '',
        region: '',
        city: '',
        phone: '',
        email: '',
        academicDegree: '',
        academicTitle: '',
        organizationType: '',
        customOrganizationType: '',
        organizationName: '',
        participationForm: '',
        reportTopic: ''
      },
      showOrganizationTypeInput: false,
      showReportTopic: false,
      loading: false,
      studyDirections: [
        { code: '01.00.00', name: '01.xx.xx МАТЕМАТИКА И МЕХАНИКА' },
        { code: '02.00.00', name: '02.xx.xx КОМПЬЮТЕРНЫЕ И ИНФОРМАЦИОННЫЕ НАУКИ' },
        { code: '03.00.00', name: '03.xx.xx ФИЗИКА И АСТРОНОМИЯ' },
        { code: '04.00.00', name: '04.xx.xx ХИМИЯ' },
        { code: '05.00.00', name: '05.xx.xx НАУКИ О ЗЕМЛЕ' },
        { code: '06.00.00', name: '06.xx.xx БИОЛОГИЧЕСКИЕ НАУКИ' },
        { code: '07.00.00', name: '07.xx.xx АРХИТЕКТУРА' },
        { code: '08.00.00', name: '08.xx.xx ТЕХНИКА И ТЕХНОЛОГИИ СТРОИТЕЛЬСТВА' },
        { code: '09.00.00', name: '09.xx.xx ИНФОРМАТИКА И ВЫЧИСЛИТЕЛЬНАЯ ТЕХНИКА' },
        { code: '10.00.00', name: '10.xx.xx ИНФОРМАЦИОННАЯ БЕЗОПАСНОСТЬ' },
        { code: '11.00.00', name: '11.xx.xx ЭЛЕКТРОНИКА, РАДИОТЕХНИКА И СИСТЕМЫ СВЯЗИ' },
        { code: '12.00.00', name: '12.xx.xx ФОТОНИКА, ПРИБОРОСТРОЕНИЕ, ОПТИЧЕСКИЕ И БИОТЕХНИЧЕСКИЕ СИСТЕМЫ И ТЕХНОЛОГИИ' },
        { code: '13.00.00', name: '13.xx.xx ЭЛЕКТРО - И ТЕПЛОЭНЕРГЕТИКА' },
        { code: '14.00.00', name: '14.xx.xx ЯДЕРНАЯ ЭНЕРГЕТИКА И ТЕХНОЛОГИИ' },
        { code: '15.00.00', name: '15.xx.xx МАШИНОСТРОЕНИЕ' },
        { code: '16.00.00', name: '16.xx.xx ФИЗИКО-ТЕХНИЧЕСКИЕ НАУКИ И ТЕХНОЛОГИИ' },
        { code: '17.00.00', name: '17.xx.xx ОРУЖИЕ И СИСТЕМЫ ВООРУЖЕНИЯ' },
        { code: '18.00.00', name: '18.xx.xx ХИМИЧЕСКИЕ ТЕХНОЛОГИИ' },
        { code: '19.00.00', name: '19.xx.xx ПРОМЫШЛЕННАЯ ЭКОЛОГИЯ И БИОТЕХНОЛОГИИ' },
        { code: '20.00.00', name: '20.xx.xx ТЕХНОСФЕРНАЯ БЕЗОПАСНОСТЬ И ПРИРОДООБУСТРОЙСТВО' },
        { code: '21.00.00', name: '21.xx.xx ПРИКЛАДНАЯ ГЕОЛОГИЯ, ГОРНОЕ ДЕЛО, НЕФТЕГАЗОВОЕ ДЕЛО И ГЕОДЕЗИЯ' },
        { code: '22.00.00', name: '22.xx.xx ТЕХНОЛОГИИ МАТЕРИАЛОВ' },
        { code: '23.00.00', name: '23.xx.xx ТЕХНИКА И ТЕХНОЛОГИИ НАЗЕМНОГО ТРАНСПОРТА' },
        { code: '24.00.00', name: '24.xx.xx АВИАЦИОННАЯ И РАКЕТНО-КОСМИЧЕСКАЯ ТЕХНИКА' },
        { code: '25.00.00', name: '25.xx.xx АЭРОНАВИГАЦИЯ И ЭКСПЛУАТАЦИЯ АВИАЦИОННОЙ И РАКЕТНО-КОСМИЧЕСКОЙ ТЕХНИКИ' },
        { code: '26.00.00', name: '26.xx.xx ТЕХНИКА И ТЕХНОЛОГИИ КОРАБЛЕСТРОЕНИЯ И ВОДНОГО ТРАНСПОРТА' },
        { code: '27.00.00', name: '27.xx.xx УПРАВЛЕНИЕ В ТЕХНИЧЕСКИХ СИСТЕМАХ' },
        { code: '28.00.00', name: '28.xx.xx НАНОТЕХНОЛОГИИ И НАНОМАТЕРИАЛЫ' },
        { code: '29.00.00', name: '29.xx.xx ТЕХНОЛОГИИ ЛЕГКОЙ ПРОМЫШЛЕННОСТИ' },
        { code: '34.00.00', name: '34.xx.xx СЕСТРИНСКОЕ ДЕЛО' },
        { code: '35.00.00', name: '35.xx.xx СЕЛЬСКОЕ, ЛЕСНОЕ И РЫБНОЕ ХОЗЯЙСТВО' },
        { code: '36.00.00', name: '36.xx.xx ВЕТЕРИНАРИЯ И ЗООТЕХНИЯ' },
        { code: '37.00.00', name: '37.xx.xx ПСИХОЛОГИЧЕСКИЕ НАУКИ' },
        { code: '38.00.00', name: '38.xx.xx ЭКОНОМИКА И УПРАВЛЕНИЕ' },
        { code: '39.00.00', name: '39.xx.xx СОЦИОЛОГИЯ И СОЦИАЛЬНАЯ РАБОТА' },
        { code: '40.00.00', name: '40.xx.xx ЮРИСПРУДЕНЦИЯ' },
        { code: '41.00.00', name: '41.xx.xx ПОЛИТИЧЕСКИЕ НАУКИ И РЕГИОНОВЕДЕНИЕ' },
        { code: '42.00.00', name: '42.xx.xx СРЕДСТВА МАССОВОЙ ИНФОРМАЦИИ И ИНФОРМАЦИОННО-БИБЛИОТЕЧНОЕ ДЕЛО' },
        { code: '43.00.00', name: '43.xx.xx СЕРВИС И ТУРИЗМ' },
        { code: '44.00.00', name: '44.xx.xx ОБРАЗОВАНИЕ И ПЕДАГОГИЧЕСКИЕ НАУКИ' },
        { code: '45.00.00', name: '45.xx.xx ЯЗЫКОЗНАНИЕ И ЛИТЕРАТУРОВЕДЕНИЕ' },
        { code: '46.00.00', name: '46.xx.xx ИСТОРИЯ И АРХЕОЛОГИЯ' },
        { code: '47.00.00', name: '47.xx.xx ФИЛОСОФИЯ, ЭТИКА И РЕЛИГИОВЕДЕНИЕ' },
        { code: '48.00.00', name: '48.xx.xx ТЕОЛОГИЯ' },
        { code: '49.00.00', name: '49.xx.xx ФИЗИЧЕСКАЯ КУЛЬТУРА И СПОРТ' },
        { code: '50.00.00', name: '50.xx.xx ИСКУССТВОЗНАНИЕ' },
        { code: '51.00.00', name: '51.xx.xx КУЛЬТУРОВЕДЕНИЕ И СОЦИОКУЛЬТУРНЫЕ ПРОЕКТЫ' },
        { code: '52.00.00', name: '52.xx.xx СЦЕНИЧЕСКИЕ ИСКУССТВА И ЛИТЕРАТУРНОЕ ТВОРЧЕСТВО' },
        { code: '53.00.00', name: '53.xx.xx МУЗЫКАЛЬНОЕ ИСКУССТВО' },
        { code: '54.00.00', name: '54.xx.xx ИЗОБРАЗИТЕЛЬНОЕ И ПРИКЛАДНЫЕ ВИДЫ ИСКУССТВ' }
      ]
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('references', [
      'fedDistricts',
      'regions',
      'academicDegrees',
      'academicTitles',
      'organizationTypes',
      'participationForms'
    ]),
    filteredFedDistricts() {
      if (this.formData.country === 'Россия') {
        return this.fedDistricts.filter(district => district.name !== 'Другое');
      }
      return this.fedDistricts;
    }
  },
  methods: {
    ...mapActions('user', ['getUserByUid']),
    ...mapActions('references', [
      'getfeddistricts',
      'getregionsbyfeddistrictid',
      'getacademicdegrees',
      'getacademictitles',
      'getorganizationtypes',
      'getparticipationforms'
    ]),
    ...mapActions('eventregistration', ['submitEventRegistration']), // Добавляем действие из нового модуля
    async submitForm() {
      const formData = {
        userid: this.user.uid,
        eventid: this.event.uid,
        lastname: this.formData.lastName,
        firstname: this.formData.firstName,
        middlename: this.formData.middleName,
        activitytype: this.formData.activityType,
        educationlevel: this.formData.educationLevel,
        course: this.formData.course,
        studydirection: this.formData.studyDirection,
        country: this.formData.country,
        feddistrict: this.formData.fedDistrict,
        region: this.formData.region,
        city: this.formData.city,
        phone: this.formData.phone,
        email: this.formData.email,
        organizationtype: this.formData.organizationType,
        organizationname: this.formData.organizationName,
        participationform: this.formData.participationForm,
        reporttopic: this.formData.reportTopic
      };

      const result = await this.submitEventRegistration(formData);
      if (result.success) {
        alert(result.message);
        this.closeModal();
      } else {
        alert(result.message);
      }
    },
    closeModal() {
      this.$emit('close');
    },
    async loadRegions() {
      if (this.formData.fedDistrict) {
        this.loading = true;
        await this.getregionsbyfeddistrictid({ feddistrictid: this.formData.fedDistrict });
        this.loading = false;
      }
    },
    handleOrganizationTypeChange() {
      this.showOrganizationTypeInput = this.formData.organizationType === '13';
    },
    handleActivityTypeChange() {
      if (this.formData.activityType === 'student') {
        this.formData.academicDegree = '';
        this.formData.academicTitle = '';
      } else if (this.formData.activityType === 'teacher') {
        this.formData.educationLevel = '';
        this.formData.course = '';
        this.formData.studyDirection = '';
      } else if (this.formData.activityType === 'other') {
        this.formData.academicDegree = '';
        this.formData.academicTitle = '';
        this.formData.educationLevel = '';
        this.formData.course = '';
        this.formData.studyDirection = '';
      }
    },
    handleParticipationFormChange() {
      const participationForm = this.participationForms.find(form => form.id === this.formData.participationForm);
      this.showReportTopic = participationForm && (participationForm.name.includes('очное участие с выступлением') || participationForm.name.includes('участие онлайн с выступлением'));
    },
    formatPhone(event) {
      let phone = event.target.value.replace(/\D/g, '');
      if (phone.length > 0 && phone[0] !== '+') {
        phone = '+' + phone;
      }
      this.formData.phone = phone;
    }
  },
  async mounted() {
    // Загрузка данных пользователя
    await this.getUserByUid();
    this.formData.lastName = this.user.surname || '';
    this.formData.firstName = this.user.name || '';
    this.formData.middleName = this.user.thirdname || '';
    this.formData.country = this.user.country || '';
    this.formData.phone = this.user.phone || '';
    this.formData.email = this.user.email || '';
    this.formData.organizationName = this.user.organization || '';

    // Загрузка данных для выпадающих списков
    await this.getfeddistricts();
    await this.getacademicdegrees();
    await this.getacademictitles();
    await this.getorganizationtypes();
    await this.getparticipationforms();

    // Если страна не Россия, заполняем поля "Федеральный округ" и "Регион" как "Другое"
    if (this.formData.country !== 'Россия') {
      this.formData.fedDistrict = this.fedDistricts.find(district => district.name === 'Другое').id;
      this.formData.region = this.regions.find(region => region.name === 'Другое').id;
    }
  }
};
</script>
  <style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  
  .home {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .event-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
  }
  
  .form-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-btn {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-btn:hover {
    background-color: #ef7d00;
  }
  
  .file-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    background-color: #f8f8f8;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  .file-input::-webkit-file-upload-button {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .file-input::-webkit-file-upload-button:hover {
    background-color: #3c218c;
  }
  
  .modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Убедитесь, что это значение выше, чем у других элементов */
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  max-height: 90%;
  margin: auto;
  border-radius: 10px;
  z-index: 1001; /* Это значение должно быть выше, чем у backdrop */
}
  
  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
    background: #3c218c;
    color: #FFFFFF;
  }
  
  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #FFFFFF;
    justify-content: space-between;
  }
  
  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }
  
  .modal-body {
    position: relative;
    padding: 20px 10px;
  }
  
  .btn-green {
    color: white;
    background: #3c218c;
    border: 1px solid #eeeeee;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .btn-add,
  .btn-remove {
    padding: 10px;
    background-color: #3c218c;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .btn-add:hover,
  .btn-remove:hover {
    background-color: #ef7d00;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  label {
    margin-bottom: 5px;
    display: block;
  }
  </style>