<template>
  <router-view/>
</template>
<meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
<style>
@font-face {
  font-family: 'Golos';
  src: url('./assets/fonts/golostext_demibold.ttf') format('truetype'); /* Путь к шрифту */
  font-weight: normal;
  font-style: normal;
}
#app {
  font-family: 'Golos', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;
}
</style>
